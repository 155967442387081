import {jwtToken} from "./Status"; 

const apiBaseUrl = process.env.REACT_APP_API_URL;

const fetchBackend = async ( endpoint: any, data: any, method = 'GET' ) => {
    const url = `${ apiBaseUrl }/${ endpoint }`;
    if ( method === 'GET' ) {
        return fetch( url,{
            mode: 'cors',
	        headers: {
            	'Access-Control-Allow-Origin': '*',
                'api-token':jwtToken
        	}
        } );
        
    } else {
        return fetch( url, {
            method,
            headers: {
                'Content-type': 'application/json',
                'api-token':jwtToken
            },
            body: JSON.stringify( data )
        });
    }
}





export {

    fetchBackend,

    apiBaseUrl

}