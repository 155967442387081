import React, { useContext, useEffect, useState } from "react";
import '../utils/css/Formulario.css';
import {AccountContext} from "./Account";
import { fetchBackend } from "./Fetch";
import { userEmail } from "./Status";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';



function Formulario() {

        /* Prueba Fetch  */
        const[countries,setCountries]=useState([]);
        const[plants,setPlants]=useState([]);
        const[promoters,setPromoters]=useState([]);
        const[families,setFamilies]=useState([{idFamily:'0', countryCode:'AR', fullName:'Calentadores'}]);
        const[capacities,setCapacities]=useState([{idCapacity:1,countryCode:"AR",idFamily:1,capacity:"110"}]);
        const[v_types,setTypes]=useState([{idType:1,countryCode:"AR",idFamily:1,type:"Electrico"}]);
        const[brands,setBrands]=useState([{idBrand:1,countryCode:"AR",idFamily:1,brand:"Acquapiú",groupBrand:"TBD"}]);
        const[descriptions,setDescriptions]=useState([{idDescription:1,countryCode:"AR",idFamily:1,description:"Gama Alta"}])
        const[clients,setClients]=useState([{idCustomer:1,firstName:"",lastName:"",fullName:"VENTA A BORDO LEON 3",customerCode:17,idPlant:0}]);
        const[sectionMeasures,setSectionMeasures]=useState([]);
        const[unitMeasures,setUnitMeasures]=useState([]);
        const[branches,setBranches]=useState([]);

        const fetchValues = async () => {
            //fetch paises
            const fetchPaises = await fetchBackend("paises","",);
            const valorPaises = fetchPaises.json();
            setCountries(await valorPaises);

            //fetch plants
            const fetchPlantas = await fetchBackend("plantas","",);
            const valorPlantas = fetchPlantas.json();
            setPlants(await valorPlantas);

            //fetch familias
            const fetchFamilias = await fetchBackend("familias","",);
            const valorFamilias = fetchFamilias.json();
            setFamilies(await valorFamilias);

            //fetch Capacities
            const fetchCapacidades = await fetchBackend("capacidades","",);
            const valorCapacidades = fetchCapacidades.json();
            setCapacities(await valorCapacidades);

            //fetch types
            const fetchTipos = await fetchBackend("tipos","",);
            const valorTipos = fetchTipos.json();
            setTypes(await valorTipos);

            //fetch Brands
            const fetchMarcas = await fetchBackend("marcas","",);
            const valorMarcas = fetchMarcas.json();
            setBrands(await valorMarcas);

            //fetch Descriptions
            const fetchDescripciones = await fetchBackend("descripciones","",);
            const valorDescripciones = fetchDescripciones.json();
            setDescriptions(await valorDescripciones);

            //fetch Promoters
            const fetchPromotores = await fetchBackend("promotores","",);
            const valorPromotores = fetchPromotores.json();
            setPromoters(await valorPromotores);

            //fetch sectionMeasures
            const fetchTramo = await fetchBackend("medidatramo","",);
            const valorTramo = fetchTramo.json();
            setSectionMeasures(await valorTramo);

            //fetch unitMeasures
            const fetchUnidad = await fetchBackend("unidadmedida","",);
            const valorUnidad = fetchUnidad.json();
            setUnitMeasures(await valorUnidad);

            //fetch branches
            const fetchRamas = await fetchBackend("ramas","",);
            const valorRamas = fetchRamas.json();
            setBranches(await valorRamas);
           
            //fetch Clients
            const fetchClientes = await fetchBackend("clientes","",);
            const valorClientes = fetchClientes.json();
            setClients(await valorClientes);
            
        }



        const {logout} = useContext(AccountContext);


        /*Hooks*/
        const[clientFilter, setClientFilter]=useState([]);
        const[client, setClient]=useState('');
        const[idClient, setIdClient]=useState('');
        const[family, setFamily]=useState([]);
        const[capacity, setCapacity]=useState([]);
        const[v_type, setType]=useState([]);
        const[brand, setBrand]=useState([]);
        const[description, setDescription] = useState([]);
        const[plant, setPlant] = useState([]);
        const[branch, setBranch] = useState([]);        
        const[search, setSearch]=useState(true);
        

        /*Values*/
        const[countryValue, setCountryValue]=useState('');
        const[plantValue, setPlantValue]=useState('');
        const[yearValue,setYearValue]=useState((new Date().getFullYear()).toString());
        const[monthValue,setMonthValue]=useState((new Date().getMonth()+1).toString());
        const[branchValue,setBranchValue]=useState('');
        const[promoterValue,setPromoterValue]=useState('');
        const[clientValue, setClientValue]=useState('');
        const[familyValue, setFamilyValue]=useState('');
        const[capacityValue, setCapacityValue]=useState('');
        const[v_typeValue, setTypesValue]=useState('');
        const[brandValue, setBrandsValue]=useState('');
        const[descriptionValue, setDescriptionValue] = useState('');
        const[sectionMeasureValue, setSectionMeasureValue]=useState('');
        const[unitMeasureValue, setUnitMeasureValue]=useState('');
        const[priceValue, setPriceValue]=useState('');


        /*Hooks modals*/
        const[modalFail, setModalFail]=useState(false);
        const[modalPost, setModalPost]=useState(false);

        const closeModalFail = () => setModalFail(false);
        const showModalFail = () => setModalFail(true);

        const closeModalPost = () => setModalPost(false);
        const showModalPost = () => setModalPost(true);


        /*Search client*/
        const handleClientInput = (name: any) => {
            setClient(name);
            setIdClient('');
            setSearch(true);
            setBranch([]);
        }

        const handleClient = (name: any, id: any) => {
            setClient(name);
            setIdClient(id);
            setClientValue(name);
            setSearch(false);
            const br: any = branches.filter(x => x.idCustomer === id);
            setBranch(br);
        }

        /*functions*/
        useEffect(()=>{
            fetchValues();
        },[])



        const handleCountry = (id: any) => {
            const fm: any = families.filter(x => x.countryCode === id);
            const pl: any = plants.filter(x => x.countryCode === id);
            setFamily(fm);
            setCapacity([]);
            setType([]);
            setBrand([]);
            setDescription([]);
            setPlant(pl);
            setCountryValue(id);
            setBranch([]);
            setClientFilter([]);
        }

        const handleFamily = (id: any) => {
            const ca: any = capacities.filter(x => x.idFamily == id);
            const ty: any = v_types.filter(x => x.idFamily == id);
            const br: any = brands.filter(x => x.idFamily == id);
            const de: any = descriptions.filter(x => x.idFamily == id);
            setCapacity(ca);
            setType(ty);
            setBrand(br);
            setDescription(de);
            setFamilyValue(id);
        }

        const handlePlant = (id: any) => {
            const cl: any = clients.filter(x => x.idPlant == id);
            setClientFilter(cl);
            setBranch([]);
            setPlantValue(id);
        }

        const handleYear = (id:any) => {
            setYearValue(id);
        }

        const handleMonth = (id:any) => {
            setMonthValue(id);
        }

        const handleBranch = (id:any) => {
            setBranchValue(id);
        }

        const handlePromoter = (id:any) => {
            setPromoterValue(id);
        }

        const handleDescription = (id:any) => {
            setDescriptionValue(id);
        }

        const handleSectionMeasure = (id:any) => {
            setSectionMeasureValue(id);
        }

        const handleUnitMeasure = (id:any) => {
            setUnitMeasureValue(id);
        }

        const handlePrice = (id:any) => {
            setPriceValue(id);
        }


        const handleCapacity = (id: any) => {
            setCapacityValue(id);
        }

        const handleType = (id: any) => {
            setTypesValue(id);
        }

        const handleBrand = (id: any) => {
            setBrandsValue(id);
        }

        const clearForm = async(event: any) => {            
            if(countryValue===''||plantValue===''||clientValue===''||monthValue===''||yearValue===''||branchValue===''||promoterValue===''||familyValue===''||capacityValue===''||v_typeValue===''||brandValue===''||sectionMeasureValue===''||unitMeasureValue===''||priceValue===''||descriptionValue===''){
                showModalFail();
                event.preventDefault();
            }else{
                const body={countryCode: countryValue,
                    idPlant: parseInt(plantValue),
                    idCustomer: parseInt(idClient),
                    month: parseInt(monthValue),
                    year: parseInt(yearValue),
                    idBranch: parseInt(branchValue),
                    idPromoter: parseInt(promoterValue),
                    idFamily: parseInt(familyValue),
                    idCapacity: parseInt(capacityValue),
                    idType: parseInt(v_typeValue),
                    idBrand: parseInt(brandValue),
                    idSectionMeasure: parseInt(sectionMeasureValue),
                    idUnitMeasure: parseInt(unitMeasureValue),
                    price: parseInt(priceValue),
                    createdUser:  userEmail,
                    idDescription: parseInt(descriptionValue)}

            const result = await fetchBackend("precios",body,"POST");
            showModalPost();
            }
        }

        const keepForm = async(event: any) => {
            event.preventDefault();
            if(countryValue===''||plantValue===''||clientValue===''||monthValue===''||yearValue===''||branchValue===''||promoterValue===''||familyValue===''||capacityValue===''||v_typeValue===''||brandValue===''||sectionMeasureValue===''||unitMeasureValue===''||priceValue===''||descriptionValue===''){
                showModalFail();
            }else{
                const body={countryCode: countryValue,
                    idPlant: parseInt(plantValue),
                    idCustomer: parseInt(idClient),
                    month: parseInt(monthValue),
                    year: parseInt(yearValue),
                    idBranch: parseInt(branchValue),
                    idPromoter: parseInt(promoterValue),
                    idFamily: parseInt(familyValue),
                    idCapacity: parseInt(capacityValue),
                    idType: parseInt(v_typeValue),
                    idBrand: parseInt(brandValue),
                    idSectionMeasure: parseInt(sectionMeasureValue),
                    idUnitMeasure: parseInt(unitMeasureValue),
                    price: parseInt(priceValue),
                    createdUser:  userEmail,
                    idDescription: parseInt(descriptionValue)}

            const result = await fetchBackend("precios",body,"POST");
            showModalPost();
            }

        }
        
        /*form*/
        return (
        <div className="container-form">
            <div className="">
                <div className="logout-button"><button className="btn btn-pos btn-light submit-design" onClick={logout}>Salir</button></div>
                <div className="row-title">
                    <h2 className='title-form'>Registrar precio</h2>
                </div>
                <form className='formulario' id='idFormulario'>
                    <div className="client-part">
                        <div className='row-form'>
                            <div className="item-form">
                            <label className=''>País</label>
                            <select required className="form-select select-design" onChange={(e) => handleCountry(e.target.value)}>
                                <option value="">
                                    Seleccione un país
                                </option>
                                {
                                    countries &&
                                    countries !== undefined ?
                                    countries.map((ctr: any,index : any) => {
                                        return(
                                            <option key={index} value={ctr.countryCode}>{ctr.country}</option>
                                            
                                        )
                                    })
                                    :"No Country"
                                }
                            </select>
                            </div>
                            <div className="item-form">
                            <label className=''>Planta</label>
                            <select required className="form-select select-design" onChange={(e) => handlePlant(e.target.value)}>
                                <option value="">
                                    Seleccione una opción
                                </option>
                                {
                                    plant &&
                                    plant !== undefined ?
                                    plant.map((ctr: any,index : any) => {
                                        return(
                                            <option key={index} value={ctr.idPlant}>{ctr.plant}</option>
                                            
                                        )
                                    })
                                    :"No plants"
                                }
                            </select>
                            </div>
                            <div className="item-form">
                                <label className=''>Cliente</label>
                                <div className="search-container">
                                    <input autoComplete="off" id='client' placeholder='Cliente' className='input-search' value={client} onChange={(e) => handleClientInput(e.target.value)}></input>
                                    <div className="dropdown">
                                    {search
                                    ?
                                    clientFilter
                                        .filter((item) => {
                                        const searchTerm = client.toLowerCase();
                                        const clientName = item.fullName.toLowerCase();

                                        return (
                                            searchTerm &&
                                            clientName.startsWith(searchTerm)
                                        );
                                        })
                                        .slice(0, 10)
                                        .map((item) => (
                                        <div
                                            onClick={() => handleClient(item.fullName, item.idCustomer)}
                                            className="dropdown-row"
                                            key={item.idCustomer}
                                        >
                                            {item.fullName}
                                        </div>
                                        ))
                                        : null
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row-form'>
                            <div className="item-form">
                            <label className=''>Año</label>
                            <select id="year" name="year" className="form-select select-design" defaultValue={new Date().getFullYear()} onChange={(e) => handleYear(e.target.value)}>
                                <option>Seleccione un año</option>
                                <option value="1980">1980</option>
                                <option value="1981">1981</option>
                                <option value="1982">1982</option>
                                <option value="1983">1983</option>
                                <option value="1984">1984</option>
                                <option value="1985">1985</option>
                                <option value="1986">1986</option>
                                <option value="1987">1987</option>
                                <option value="1988">1988</option>
                                <option value="1989">1989</option>
                                <option value="1990">1990</option>
                                <option value="1991">1991</option>
                                <option value="1992">1992</option>
                                <option value="1993">1993</option>
                                <option value="1994">1994</option>
                                <option value="1995">1995</option>
                                <option value="1996">1996</option>
                                <option value="1997">1997</option>
                                <option value="1998">1998</option>
                                <option value="1999">1999</option>
                                <option value="2000">2000</option>
                                <option value="2001">2001</option>
                                <option value="2002">2002</option>
                                <option value="2003">2003</option>
                                <option value="2004">2004</option>
                                <option value="2005">2005</option>
                                <option value="2006">2006</option>
                                <option value="2007">2007</option>
                                <option value="2008">2008</option>
                                <option value="2009">2009</option>
                                <option value="2010">2010</option>
                                <option value="2011">2011</option>
                                <option value="2012">2012</option>
                                <option value="2013">2013</option>
                                <option value="2014">2014</option>
                                <option value="2015">2015</option>
                                <option value="2016">2016</option>
                                <option value="2017">2017</option>
                                <option value="2018">2018</option>
                                <option value="2019">2019</option>
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                                <option value="2029">2029</option>
                                <option value="2030">2030</option>
                                <option value="2031">2031</option>
                                <option value="2032">2032</option>
                                <option value="2033">2033</option>
                                <option value="2034">2034</option>
                                <option value="2035">2035</option>
                                <option value="2036">2036</option>
                                <option value="2037">2037</option>
                                <option value="2038">2038</option>
                                <option value="2039">2039</option>
                                <option value="2040">2040</option>
                                <option value="2041">2041</option>
                                <option value="2042">2042</option>
                                <option value="2043">2043</option>
                                <option value="2044">2044</option>
                                <option value="2045">2045</option>
                                <option value="2046">2046</option>
                                <option value="2047">2047</option>
                                <option value="2048">2048</option>
                                <option value="2049">2049</option>
                                <option value="2050">2050</option>
                                <option value="2051">2051</option>
                                <option value="2052">2052</option>
                                <option value="2053">2053</option>
                                <option value="2054">2054</option>
                                <option value="2055">2055</option>
                                <option value="2056">2056</option>
                                <option value="2057">2057</option>
                                <option value="2058">2058</option>
                                <option value="2059">2059</option>
                                <option value="2060">2060</option>
                                <option value="2061">2061</option>
                                <option value="2062">2062</option>
                                <option value="2063">2063</option>
                                <option value="2064">2064</option>
                                <option value="2065">2065</option>
                                <option value="2066">2066</option>
                                <option value="2067">2067</option>
                                <option value="2068">2068</option>
                                <option value="2069">2069</option>
                                <option value="2070">2070</option>
                                <option value="2071">2071</option>
                                <option value="2072">2072</option>
                                <option value="2073">2073</option>
                                <option value="2074">2074</option>
                                <option value="2075">2075</option>
                                <option value="2076">2076</option>
                                <option value="2077">2077</option>
                                <option value="2078">2078</option>
                                <option value="2079">2079</option>
                                <option value="2080">2080</option>
                                <option value="2081">2081</option>
                                <option value="2082">2082</option>
                                <option value="2083">2083</option>
                                <option value="2084">2084</option>
                                <option value="2085">2085</option>
                                <option value="2086">2086</option>
                                <option value="2087">2087</option>
                                <option value="2088">2088</option>
                                <option value="2089">2089</option>
                                <option value="2090">2090</option>
                                <option value="2091">2091</option>
                                <option value="2092">2092</option>
                                <option value="2093">2093</option>
                                <option value="2094">2094</option>
                                <option value="2095">2095</option>
                                <option value="2096">2096</option>
                                <option value="2097">2097</option>
                                <option value="2098">2098</option>
                                <option value="2099">2099</option>
                                <option value="2100">2100</option>
                                <option value="2101">2101</option>
                                <option value="2102">2102</option>
                                <option value="2103">2103</option>
                                <option value="2104">2104</option>
                                <option value="2105">2105</option>
                                <option value="2106">2106</option>
                                <option value="2107">2107</option>
                                <option value="2108">2108</option>
                                <option value="2109">2109</option>
                                <option value="2110">2110</option>
                                <option value="2111">2111</option>
                                <option value="2112">2112</option>
                                <option value="2113">2113</option>
                                <option value="2114">2114</option>
                                <option value="2115">2115</option>
                                <option value="2116">2116</option>
                                <option value="2117">2117</option>
                                <option value="2118">2118</option>
                                <option value="2119">2119</option>
                                <option value="2120">2120</option>
                                <option value="2121">2121</option>
                                <option value="2122">2122</option>
                                <option value="2123">2123</option>
                                <option value="2124">2124</option>
                                <option value="2125">2125</option>
                                <option value="2126">2126</option>
                                <option value="2127">2127</option>
                                <option value="2128">2128</option>
                                <option value="2129">2129</option>
                                <option value="2130">2130</option>
                                <option value="2131">2131</option>
                                <option value="2132">2132</option>
                                <option value="2133">2133</option>
                                <option value="2134">2134</option>
                                <option value="2135">2135</option>
                                <option value="2136">2136</option>
                                <option value="2137">2137</option>
                                <option value="2138">2138</option>
                                <option value="2139">2139</option>
                                <option value="2140">2140</option>
                                <option value="2141">2141</option>
                                <option value="2142">2142</option>
                                <option value="2143">2143</option>
                                <option value="2144">2144</option>
                                <option value="2145">2145</option>
                                <option value="2146">2146</option>
                                <option value="2147">2147</option>
                                <option value="2148">2148</option>
                                <option value="2149">2149</option>
                                <option value="2150">2150</option>
                                <option value="2151">2151</option>
                                <option value="2152">2152</option>
                                <option value="2153">2153</option>
                                <option value="2154">2154</option>
                                <option value="2155">2155</option>
                                <option value="2156">2156</option>
                                <option value="2157">2157</option>
                                <option value="2158">2158</option>
                                <option value="2159">2159</option>
                                <option value="2160">2160</option>
                                <option value="2161">2161</option>
                                <option value="2162">2162</option>
                                <option value="2163">2163</option>
                                <option value="2164">2164</option>
                                <option value="2165">2165</option>
                                <option value="2166">2166</option>
                                <option value="2167">2167</option>
                                <option value="2168">2168</option>
                                <option value="2169">2169</option>
                                <option value="2170">2170</option>
                                <option value="2171">2171</option>
                                <option value="2172">2172</option>
                                <option value="2173">2173</option>
                                <option value="2174">2174</option>
                                <option value="2175">2175</option>
                                <option value="2176">2176</option>
                                <option value="2177">2177</option>
                                <option value="2178">2178</option>
                                <option value="2179">2179</option>
                                <option value="2180">2180</option>
                                <option value="2181">2181</option>
                                <option value="2182">2182</option>
                                <option value="2183">2183</option>
                                <option value="2184">2184</option>
                                <option value="2185">2185</option>
                                <option value="2186">2186</option>
                                <option value="2187">2187</option>
                                <option value="2188">2188</option>
                                <option value="2189">2189</option>
                                <option value="2190">2190</option>
                                <option value="2191">2191</option>
                                <option value="2192">2192</option>
                                <option value="2193">2193</option>
                                <option value="2194">2194</option>
                                <option value="2195">2195</option>
                                <option value="2196">2196</option>
                                <option value="2197">2197</option>
                                <option value="2198">2198</option>
                                <option value="2199">2199</option>
                                <option value="2200">2200</option>
                            </select>

                            </div>
                            <div className="item-form">
                            <label className=''>Mes</label>
                            <select className="form-select select-design" defaultValue={new Date().getMonth()+1} onChange={(e) => handleMonth(e.target.value)}>
                                <option value="">
                                    Seleccione una opción
                                </option>
                                <option value="1">
                                    Enero
                                </option>
                                <option value="2">
                                    Febrero
                                </option>
                                <option value="3">
                                    Marzo
                                </option>
                                <option value="4">
                                    Abril
                                </option>
                                <option value="5">
                                    Mayo
                                </option>
                                <option value="6">
                                    Junio
                                </option>
                                <option value="7">
                                    Julio
                                </option>
                                <option value="8">
                                    Agosto
                                </option>
                                <option value="9">
                                    Septiembre
                                </option>
                                <option value="10">
                                    Octubre
                                </option>
                                <option value="11">
                                    Noviembre
                                </option>
                                <option value="12">
                                    Diciembre
                                </option>
                            </select>
                            </div>
                            <div className="item-form">
                            <label className=''>Sucursal</label>
                            <select className="form-select select-design" onChange={(e) => handleBranch(e.target.value)}>
                                <option value="">
                                    Seleccione una opción
                                </option>
                                {
                                    branch &&
                                    branch !== undefined ?
                                    branch.map((ctr: any,index : any) => {
                                        return(
                                            <option key={index} value={ctr.idBranch}>{ctr.branchOffice}</option>
                                            
                                        )
                                    })
                                    :"No Promoter"
                                }
                            </select>
                            </div>
                        </div>
                        <div className='row-form'>
                            <div className="item-form">
                            <label className=''>Promotor/Marca</label>
                            <select className="form-select select-design" onChange={(e) => handlePromoter(e.target.value)}>
                                <option value="">
                                    Seleccione una opción
                                </option>
                                {
                                    promoters &&
                                    promoters !== undefined ?
                                    promoters.map((ctr: any,index : any) => {
                                        return(
                                            <option key={index} value={ctr.idPromoter}>{ctr.promoter}</option>
                                            
                                        )
                                    })
                                    :"No Promoter"
                                }
                            </select>
                            </div>
                        </div>
                    </div>
                    <div className="product-part">
                        <div className='row-form'>
                            <div className="item-form">
                            <label className=''>Familia</label>
                            <select className="form-select select-design" onChange={(e) => handleFamily(e.target.value)}>
                                <option value="">
                                    Seleccione una opción
                                </option>
                                {
                                    family &&
                                    family !== undefined ?
                                    family.map((ctr: any,index : any) => {
                                        return(
                                            <option key={index} value={ctr.idFamily}>{ctr.fullName}</option>
                                            
                                        )
                                    })
                                    :"No Family"
                                }
                            </select>
                            </div>
                            <div className="item-form">
                            <label className=''>Capacidad</label>
                            <select className="form-select select-design" onChange={(e) => handleCapacity(e.target.value)}>
                                <option value="">
                                    Seleccione una opción
                                </option>
                                {
                                    capacity &&
                                    capacity !== undefined ?
                                    capacity.map((ctr: any,index : any) => {
                                        return(
                                            <option key={index} value={ctr.idCapacity}>{ctr.capacity}</option>
                                            
                                        )
                                    })
                                    :"No capacity"
                                }
                            </select>
                            </div>
                            <div className="item-form">
                            <label className=''>Tipo</label>
                            <select className="form-select select-design" onChange={(e) => handleType(e.target.value)}>
                                <option value="">
                                    Seleccione una opción
                                </option>
                                {
                                    v_type &&
                                    v_type !== undefined ?
                                    v_type.map((ctr: any,index : any) => {
                                        return(
                                            <option key={index} value={ctr.idType}>{ctr.type}</option>
                                            
                                        )
                                    })
                                    :"No type"
                                }
                            </select>
                            </div>
                            
                        </div>
                        <div className='row-form'>
                            <div className="item-form">
                            <label className=''>Descripción</label>
                            <select className="form-select select-design" onChange={(e) => handleDescription(e.target.value)}>
                                <option value="">
                                    Seleccione una opción
                                </option>
                                {
                                    description &&
                                    description !== undefined ?
                                    description.map((ctr: any,index : any) => {
                                        return(
                                            <option key={index} value={ctr.idDescription}>{ctr.description}</option>
                                            
                                        )
                                    })
                                    :"No type"
                                }
                            </select>
                            </div>
                            <div className="item-form">
                            <label className=''>Medida tramo</label>
                            <select className="form-select select-design" onChange={(e) => handleSectionMeasure(e.target.value)}>
                                <option value="">
                                    Seleccione una opción
                                </option>
                                {
                                    sectionMeasures &&
                                    sectionMeasures !== undefined ?
                                    sectionMeasures.map((ctr: any,index : any) => {
                                        return(
                                            <option key={index} value={ctr.idSectionMeasure}>{ctr.sectionMeasure}</option>
                                            
                                        )
                                    })
                                    :"No Values"
                                }
                                
                            </select>
                            </div>
                            <div className="item-form">
                            <label className=''>Marca</label>
                            <select className="form-select select-design" onChange={(e) => handleBrand(e.target.value)}>
                                <option value="">
                                    Seleccione una opción
                                </option>
                                {
                                    brand &&
                                    brand !== undefined ?
                                    brand.map((ctr: any,index : any) => {
                                        return(
                                            <option key={index} value={ctr.idBrand}>{ctr.brand}</option>
                                            
                                        )
                                    })
                                    :"No brand"
                                }
                            </select>
                            </div>
                        </div>
                        <div className="row-form">
                            <div className="item-form">
                            <label className=''>Unidad de medida</label>
                            <select className="form-select select-design" onChange={(e) => handleUnitMeasure(e.target.value)}>
                                <option value="">
                                    Seleccione una opción
                                </option>
                                {
                                    unitMeasures &&
                                    unitMeasures !== undefined ?
                                    unitMeasures.map((ctr: any,index : any) => {
                                        return(
                                            <option key={index} value={ctr.idUnitMeasure}>{ctr.unitMeasure}</option>
                                            
                                        )
                                    })
                                    :"No Values"
                                }
                                
                            </select>
                            </div>
                            <div className="item-form">
                            <label className=''>Precio c/iva</label>
                            <input placeholder='Ingresar precio unitario con IVA' type="number" className='input-design' onChange={(e) => handlePrice(e.target.value)}></input>
                            </div>
                        </div>
                    </div>
                    <div className='row-buttons'>
                        <button type="submit" className="btn btn-pos btn-light submit-design" onClick={clearForm}>Registrar y limpiar</button>
                        <button type="submit" className="btn btn-pos btn-light submit-design" onClick={keepForm}>Registrar y mantener</button>
                    </div>
                </form>
            </div>
            <div className="modalFailure">
                <Modal show={modalFail} onHide={closeModalFail}>
                    <Modal.Header closeButton>
                    <Modal.Title>Falta información</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Faltan los siguientes campos:
                        <p></p>
                        {countryValue === '' ? <p>País</p> : ''}
                        {plantValue === '' ? <p>Planta</p> : ''}
                        {idClient === '' ? <p>Cliente</p> : ''}
                        {monthValue === '' ? <p>Mes</p> : ''}
                        {yearValue === '' ? <p>Año</p> : ''}
                        {branchValue === '' ? <p>Sucursal</p> : ''}
                        {promoterValue === '' ? <p>Promotor</p> : ''}
                        {familyValue === '' ? <p>Familia</p> : ''}
                        {capacityValue === '' ? <p>Capacidad</p> : ''}
                        {v_typeValue === '' ? <p>Tipo</p> : ''}
                        {brandValue === '' ? <p>Marca</p> : ''}
                        {sectionMeasureValue === '' ? <p>Medida tramo</p> : ''}
                        {unitMeasureValue === '' ? <p>Unidad de medida</p> : ''}
                        {priceValue === '' ? <p>Precio</p> : ''}
                        {descriptionValue === '' ? <p>Descripción</p> : ''}
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="primary" onClick={closeModalFail}>
                        OK
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div className="modalPost">
                <Modal show={modalPost} onHide={closeModalPost}>
                    <Modal.Header closeButton>
                    <Modal.Title>El formulario se envió correctamente.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>La información ha sido cargada correctamente.</Modal.Body>
                    <Modal.Footer>
                    <Button variant="primary" onClick={closeModalPost}>
                        OK
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
        );
}

export default Formulario;