import React, { useState, useContext} from "react";
import {AccountContext} from "./Account";
import '../utils/css/Login.css';

function Login() {
    const[email,setEmail] = useState("");
    const[password,setPassword] = useState("");

    const {authenticate} = useContext(AccountContext);

    const handleEmail = (value: any) => {
        setEmail(value);
    }

    const handlePassword = (value: any) => {
        setPassword(value);
    }

    const onSubmit = (event : any) => {
        event.preventDefault();
        authenticate(email, password)
            .then((data: any) => {
                console.log("Logged in", data);
            })
            .catch((err: any) => {
                console.error("Failed to Login", err);
            })
    }

    return(
        <div className="login">
            <form className="login-container" onSubmit={onSubmit}>
                <h2>Iniciar sesión</h2>
                <div className="login-section">
                    <input required id="user" placeholder="Usuario" className="login-input" value={email} onChange={(e) => handleEmail(e.target.value)} autoComplete="off"></input>
                    <label htmlFor="user" className="login-label">Usuario</label>
                </div>
                <div className="login-section">
                    <input required type="password" id="password" placeholder="Contraseña" className="login-input" value={password} onChange={(e) => handlePassword(e.target.value)} autoComplete="off"></input>
                    <label htmlFor="password" className="login-label">Contraseña</label>
                </div>
                <div className="login-section">
                    <button className="btn btn-pos btn-light submit-design">Iniciar sesión</button>
                </div>
            </form>
        </div>
    );
}   

export default Login;