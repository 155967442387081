import image from './utils/images/logo.png';
import './App.css';
import { Account } from './components/Account';
import {Status} from './components/Status';


function App() {

  return (
    <div className='App'>
      <img src={image} className="main-logo" alt="logo"></img>
      <Account> 
      <Status></Status>
      </Account>
    </div>
  );
}
//
export default App;
