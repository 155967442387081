import React, {useContext, useEffect, useState} from "react";
import { AccountContext } from "./Account";
import Login from "./Login";
import Formulario from "./Form";

let jwtToken: any;
let userEmail: any;

const Status = () => {
    const [status, setStatus] = useState(false);

    const {getSession} = useContext(AccountContext);

    useEffect(() => {
        getSession().then((session: any) => {
            setStatus(true);
            jwtToken=session.accessToken.jwtToken;
            userEmail=session.idToken.payload.email;
        });
    }, []);

    return <div>{status ? <Formulario/> : <Login/>}</div>
};

export {Status, jwtToken, userEmail};